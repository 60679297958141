@import '~react-phone-input-2/lib/style.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// react-phone-input-2
.react-tel-input .form-control {
    width: 100% !important;
    height: 32px !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px;
    border: 2px dashed #eee;
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;

    span.anticon {
        font-size: 48px;
        color: #1677ff;
    }
}