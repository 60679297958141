.courses-hero {
    min-height: 400px;
    background: linear-gradient(rgba(74, 95, 255, 0.85), rgba(74, 95, 255, 0.85)),
        url('https://res.cloudinary.com/dbfje95cg/image/upload/v1679913512/bg_fsjnsv.jpg') no-repeat center / cover;

    h1 {
        letter-spacing: 2px;
        font-weight: 600;
    }

    button {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
    }

    .form-control {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
    }

    .form-control::placeholder {
        color: $secondary;
        padding: 0px;
    }

}