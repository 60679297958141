.bg-auth {
    min-height: 100vh;
    background: linear-gradient(rgba(96, 96, 96, 0), rgba(96, 96, 96, 0)),
        url('https://media.istockphoto.com/id/589415708/photo/fresh-fruits-and-vegetables.jpg?s=1024x1024&w=is&k=20&c=mb1EBDCszi7HP1FxgCPNTh3N1IgV03_N4rCnO_AtStc=') no-repeat center / cover;

    .ant-input::placeholder {
        color: rgb(0, 0, 0);
    }

}

.auth-card {
    background: rgba(255, 255, 255, 0.489);
}