.btn-secondary {
    width: 140px;
    padding: 10px 7px;
    background: $secondary;
    color: $primary;
    border: none;
    font-weight: 600;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .btn-secondary:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    color: $secondary;
  }
  .btn-secondary:hover:before, .btn-secondary:focus:before, .btn-secondary:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }


.btn-dark {
    width: 140px;
    padding: 10px 7px;
    background: $primary;
    color: $secondary;
    font-weight: 600;
    vertical-align: middle;
    border: none;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .btn-dark:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $secondary;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .btn-dark:hover, .btn-dark:focus, .btn-dark:active {
    color: $primary;
  }
  .btn-dark:hover:before, .btn-dark:focus:before, .btn-dark:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  

.btn-light {
    width: 140px;
    padding: 10px 7px;
    background: $primary;
    color: $secondary;
    font-weight: 600;
    vertical-align: middle;
    border: none;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .btn-light:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .btn-light:hover, .btn-light:focus, .btn-light:active {
    color: $primary;
  }
  .btn-light:hover:before, .btn-light:focus:before, .btn-light:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  