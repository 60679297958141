#topbar {
    background-color: $dark;
    height: 45px;
    overflow: hidden;

    a {
        &:hover {
            color: $secondary !important;
        }
    }

}

.logoClass {
    max-height: 40px;
}