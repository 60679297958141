.about-hero {
    min-height: 400px;
    background: linear-gradient(rgba(96, 96, 96, 0), rgba(96, 96, 96, 0)),
        url('https://peacefulqode.com/themes/nutritius/html/images/blog/Breadcamp-image.jpg') no-repeat center / cover;

    h1 {
        letter-spacing: 2px;
        font-weight: 600;
    }
}

.slick-next:before,
.slick-prev:before {
    color: $secondary;
}