.faqs-hero {
    min-height: 400px;
    background: linear-gradient(rgba(0, 0, 0, 0.603), rgba(0, 0, 0, 0.639)),
        url('https://res.cloudinary.com/dbfje95cg/image/upload/v1678716172/LUNCHING-01_bfpnms_ejrheq.jpg') no-repeat center / cover;

}

.faqs-section {
    .ant-collapse-arrow {
        display: none !important;
    }
}