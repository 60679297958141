.card-shahdow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

// Carousel styling

.carousel-item {
    .carousel-caption {
        position: absolute;
        top: 35%;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

// Home About section styling

@media screen and (max-Width:450px) {
    .home-about {
        h2 {
            font-size: 22px;
        }
    }
}

@media screen and (max-Width:420px) {
    .home-about {
        h2 {
            font-size: 20px;
        }
    }
}

// Home Services section styling
.card-hover {
    .card-icon {
        background: white;
    }

    .card-img {
        transition: transform .2s;
    }
}

.card-hover:hover {
    .card-icon {
        background: $secondary;
    }

    .card-img {
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

// Honme calculator styling

.ant-input::placeholder {
    color: white;
}

// Home Skill section styling

.ant-progress-inner {
    .ant-progress-bg {
        background: #7fb82d;
    }
}

@media screen and (max-Width:560px) {
    .home-skill {
        h1 {
            font-size: 24px;
        }
    }
}

// Home Appointment Section styling
.home-appointment {
    .ant-input::placeholder {
        color: black;
    }
}
