// @import url('https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// @font-face {
//     font-family: "Mehr Nastaliq Urdu";
//     src: url("../assets/fonts/Mehr_Nastaliq_Web_v.2.0.woff");
// }

// .text-urdu {
//     font-family: 'Noto Nastaliq Urdu', serif;
// }

    // .text-urdu-mehr {
    //     font-family: 'Mehr Nastaliq Urdu', serif;
    // }

.text-eng {
    font-family: 'Montserrat', sans-serif;
}