input::placeholder {
  color: white;
  font-size: 18px;
}

@media screen and (max-Width:992px) {
  .sub-footer {
      h1 {
        margin-top: 8px !important;
          font-size: 29px;
      }
  }
}

@media screen and (max-Width:768px) {
  .sub-footer {
      h1 {
          font-size: 34px;
      }
  }
}