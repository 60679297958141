// Appointment Hero Styling

.appointment-hero {
    min-height: 400px;
    background: linear-gradient(rgba(96, 96, 96, 0), rgba(96, 96, 96, 0)),
        url('https://peacefulqode.com/themes/nutritius/html/images/blog/Breadcamp-image.jpg') no-repeat center / cover;

    h1 {
        letter-spacing: 2px;
        font-weight: 600;
    }
}

// Appointment Card Styling

.appointment-card {
    .ant-select-selector {
        height: 50px !important;
    }

    .ant-select-selection-item {
        margin-top: 8px !important;
        font-size: 17px !important;
    }

    .label {
        font-weight: 600 !important;
    }

    .ant-input::placeholder {
        color: black;
    }

    .ant-picker-input::placeholder {
        color: black;
    }
}

@media screen and (max-Width:560px) {
    .appointment-card2 {
        h1 {
            font-size: 24px;
        }
    }
}